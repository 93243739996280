import React from "react"
import { PageContextProvider } from "./src/providers/page_context"
import { Toaster } from "react-hot-toast"
import "./src/styles/global.scss"

export const wrapPageElement = ({ element, props }) => (
  <main>
    <PageContextProvider {...props}>{element}</PageContextProvider>
    <Toaster />
  </main>
)
